import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {Layout} from '@components/layout/layout'
import { H1, H3, Paragph } from "@components/layout/style"
import { MainContainer, RightContainer, StyledHero, ListDisc, B } from "../../components/oferta.style"

const Ortodoncja:React.FC = ({data}) => {
  return (<Layout data={data} prop={{
    canonicalUrl: 'https://www.mediroot.pl/oferta/ortodoncja-wagrowiec/',
    title: 'Ortodonta Wągrowiec - leczenie i zabiegi ortodontyczne | Mediroot',
    description: 'Mediroot ➤ Ortodonta Wągrowiec - Leczenie ortodontyczne ✔ Ortodoncja dla dorosłych ✔ Aparaty ortodontyczne ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
    <StyledHero>
      <StaticImage
        src="../../assets/images/hero.jpg"
        placeholder="blurred"
        alt="leczenie ortodontyczne, etapy leczenia ortodontycznego, leczenie aparatem ortodontycznym, ortodonta Wągrowiec, Wągrowiec ortodonta, zabiegi ortodontyczne, ortodoncja dla dorosłych, leczenie ortodontyczne dorosłych"
        layout="fullWidth"/>
    </StyledHero>
    <MainContainer>
      <RightContainer>
        <H1>
          Ortodonta Wągrowiec
        </H1>

        <Paragph>
        Leczenie wad zgryzu wpływa nie tylko na poprawę estetyki uśmiechu, ale ma także duże znaczenie dla zdrowia
całej jamy ustnej. Decyzję o tym, czy u danego pacjenta należy zastosować leczenie aparatem ortodontycznym,
dentysta podejmuje na podstawie badania radiologicznego oraz innych technik diagnostycznych. To ważne,
ponieważ decyduje o kolejnych etapach leczenia. W Mediroot skorygujemy każdą wadę niezależnie, czy jest to
zgryz krzyżowy, przewieszony, głęboki, otwarty czy tyło- lub przodozgryz. Wszystkie wykonywane zabiegi
ortodontyczne przebiegają w przyjaznej atmosferze i z użyciem najnowocześniejszego sprzętu.
        </Paragph>

        <H3>
        Czym zajmuje się ortodoncja?
        </H3>

        <Paragph>
        Ortodoncja to jeden z działów stomatologii, który zajmuje się przede wszystkim leczeniem wad zgryzu. Przez
wadę zgryzu należy rozumieć nieprawidłowe ustawienie zębów w łukach zębowych, ale również takie sytuacje, w
których zęby są "proste" lecz łuki zębowe zwierają się w nieprawidłowy sposób, np. górne zęby są znacznie
wysunięte do przodu względem dolnych. Taki nieprawidłowy układ górnego i dolnego łuku zębowego może być
zauważalny przez samego pacjenta, ujawniać się w rysach twarzy.
        </Paragph>

        <H3>
        Leczenie ortodontyczne – jakie wady zgryzu obejmuje?
        </H3>

        <Paragph>
        Rozpoznanie wady zgryzu jest stwierdzane na podstawie odchyleń od tzw. normy
okluzji, która określa prawidłowe ustawienie uzębienia względem siebie (stykanie się
zębów górnych z dolnymi), ustawienie stawu skroniowo-żuchwowego oraz mięśni
twarzy.
        </Paragph>

        <H3>
        Diagnostyka wad zgryzu odnosi się do trzech płaszczyzn:
        </H3>

        <ListDisc>
          <li>
          strzałkowej (pośrodkowej) – która dzieli całe ciało na dwie
symetryczne względem siebie części. W płaszczyźnie tej rozpatrujemy
możliwe zwężenia, asymetrie czy rozszerzenia;
          </li>
          <li>
          czołowej – która stanowi płaszczyznę równoległą do podłoża i styczną
do czoła. W niej rozpatrujemy możliwe cofnięcia i wysunięcia;
          </li>
          <li>
          poziomej – która stanowi płaszczyznę przechodzącą na wysokości
kontaktu zębów górnych z dolnymi. Tu rozpatrujemy wydłużenia i
skrócenia w obrębie dolnego odcinka twarzy.
          </li>
        </ListDisc>

        <H3>
        W stosunku do płaszczyzny pośrodkowej klasyfikuje się wady poprzeczne zgryzu, do których należą:
        </H3>

        <ListDisc>
          <li>
          zgryz krzyżowy – w którym jeden bądź kilka zębów żuchwy wysuwa
się na zewnątrz od zębów szczęki.
          </li>
          <li>
          boczne przemieszczenie żuchwy – w którym warga dolna i bródka
przesunięte są w prawą lub lewą stronę. Wada ta może być
czynnościowa (związana chociażby z nieprawidłową pracą mięśni) lub
morfologiczna (wynikająca z nieprawidłowej budowy anatomicznej
żuchwy).
          </li>
          <li>
          zgryz przewieszony – w którym jeden bądź kilka zębów szczęki
nadmiernie wysuwa się na zewnątrz i zamiast kontaktowania się z
zębem dolnym całkowicie go przykrywa. Wada ta związana jest z
nieprawidłowym ustawieniem zęba, niedorozwojem żuchwy bądź
przerostem szczęki.
          </li>
        </ListDisc>

        <H3>
        W stosunku do płaszczy czołowej klasyfikuje się natomiast
wady przednio-tylne, a dokładniej:
        </H3>

        <ListDisc>
          <li>
          wady dotylne takie jak tyłozgryz, który polega na nadmiernym
cofnięciu się zębów dolnych do tyłu w stosunku do górnych zębów.
Spowodowany jest na ogół nadmiernym wzrostem szczęki bądź
zahamowaniem wzrostu części zębodołowej żuchwy. Wadą dotylną jest
również tyłożuchwie, polegające na nadmiernym przesunięciu całej
żuchwy do tyłu. Wady te mogą wynikać z nieprawidłowego toru
oddechowego (tzn. przez usta), długotrwałego ssania palca, smoczka
lub wargi dolnej, wad postawy oraz sztucznego karmienia dziecka w
wieku niemowlęcym.
          </li>
          <li>
          wady doprzednie, w których zęby przednie dolne ustawiają się przed
górnymi. Takie osoby posiadają upośledzoną funkcję żucia i
odgryzania pokarmów oraz mogą mieć zaburzoną wymowę niektórych
zgłosek. Wśród wad doprzednich można wymienić przodozgryz oraz
przodożuchwie.
          </li>
        </ListDisc>


        <H3>
        W stosunku do ostatniej płaszczyzny, poziomej,
rozpatrujemy wady pionowe:
        </H3>

        <ListDisc>
          <li>
          zgryz otwarty – który polega na braku kontaktu zębów dolnych z
górnymi. Osoby z tą wadą mogą posiadać problemy z żuciem i
odgryzaniem pokarmów oraz z prawidłową wymową (seplenienie).
Przyczyną zgryzu otwartego może być ssanie smoczka, palca czy wargi
dolnej, nieprawidłowe ustawianie języka między łuki zębowe, krzywica,
zaburzenia hormonalne lub uwarunkowania genetyczne. Rozległa wada
wizualnie objawia się wydłużonym dolnym odcinkiem twarzy.
          </li>
          <li>
          zgryz głęboki – który polega na nadmiernym zakryciu zębów górnych
przez dolne. Rozległa wada wizualnie objawia się skróceniem dolnego
odcinka twarzy.
          </li>
        </ListDisc>

        <H3>
        Wady zgryzu, które odnoszą się również w stosunku do
wszystkich trzech płaszczyzn:
        </H3>

        <ListDisc>
          <li>
          wielkożuchwie – nadmierny jej wzrost we wszystkich wymiarach.
          </li>
          <li>
          małożuchwie – niedorozwój żuchwy we wszystkich wymiarach.
          </li>
          <li>
          wielka szczęka – nadmierny wzrost szczęki we wszystkich wymiarach.
          </li>
          <li>
          mała szczęka – ograniczony jej wzrost we wszystkich wymiarach.
          </li>
        </ListDisc>

        <H3>
        Ostatnią grupę stanowią wady związane z samymi zębami:
        </H3>

        <ListDisc>
          <li>
          stłoczenia zębów, które wynikają z braku wystarczającej ilości
miejsca w łuku dla wszystkich zębów, przez co nachodzą na siebie,
wyrzynają się w nieprawidłowym miejscu (np. poza łukiem) lub pod
nieodpowiednim kątem.
          </li>
          <li>
          zaburzenia budowy zębów takie jak wielko- czy małozębie, zęby zlane
i zrośnięte czy zaburzenia budowy w obrębie korzeni zębów.
          </li>
          <li>
          nieprawidłowa liczba zębów – zęby dodatkowe z prawidłową bądź
nieprawidłową budową, zmniejszona liczba zębów bądź całkowity ich
brak;
          </li>
          <li>
          zaburzenia położenia zębów w obrębie łuków zębowych (wychylenia,
rotacje) i poza nimi.
          </li>
          <li>
          zaburzenia czasu wyrzynania się zębów takie jak opóźnienie czy
przyspieszenie.
          </li>
        </ListDisc>

        <H3>
          Dlaczego warto leczyć wady zgryzu w Mediroot Wągrowiec?
        </H3>

        <Paragph>
        Ortodonta to specjalista, który musi budzić zaufanie, ponieważ decydując się na założenie aparatu
ortodontycznego, pacjent wiąże się z nim na co najmniej kilka miesięcy, jeśli nie kilka lat. W Mediroot nasz
personel dokłada wszelkich starań, aby świadczone usługi były na najwyższym poziomie, a każdy pacjent czuł
się komfortowo. Staramy się również uświadamiać tych, którzy do nas przychodzą, że nieleczone wady zgryzu to
nie tylko kwestie estetyczne, ale mogą doprowadzić do problemów związanych z utrzymaniem odpowiedniej
higieny jamy ustnej, a tym samym zwiększać ryzyko wystąpienia próchnicy i chorób dziąseł. Do każdego
podchodzimy indywidualnie, dobierając optymalne metody leczenia. Przez cały czas sprawujemy też opiekę nad
naszymi pacjentami, udzielając im merytorycznego wsparcia, by świadomym zachowaniem przyczyniali się do
utrwalania efektów leczenia.
        </Paragph>

        <Paragph>
        Ortodonci w Mediroot kompleksowo zadbają o każdy etap leczenia ortodontycznego.
        </Paragph>

      </RightContainer>
    </MainContainer>


  </Layout>);
};

export default Ortodoncja;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
